import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styles from './Input.module.css'

function Input(props) {
  const input = useRef()
  useEffect(() => {
    input.current.focus()
  }, [])
  return (
    <div className={styles.root}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.inputWrap}>
        <input
          ref={input}
          className={styles.input}
          type={props.type}
          name={props.name}
          placeholder={props.placeholder}
          value={props.value}
          onChange={e => props.onUpdate(props.name, e.target.value)}
        />
      </div>
      {props.helperText && <p className={styles.helper}>{props.helperText}</p>}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onUpdate: PropTypes.func.isRequired
}

export default Input
