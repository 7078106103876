import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import styles from './Controls.module.css'

function ControlButton(props) {
  if (props.type === 'back') {
    return (
      <button onClick={props.onClick}>
        <span className={`${styles.triangle} ${styles.triangleLeft}`} />
        {props.label}
      </button>
    )
  }

  if (props.type === 'end') {
    return (
      <Link to={`${props.locale === 'en' ? '/en/' : '/'}`}>
        <button onClick={props.onClick}>
          {props.label}
          <span className={`${styles.triangle} ${styles.triangleRight}`} />
        </button>
      </Link>
    )
  }

  return (
    <button onClick={props.onClick} id={props.id}>
      {props.label} <span className={`${styles.triangle} ${styles.triangleRight}`} />
    </button>
  )
}

function Controls(props) {
  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('keypress', keyPressed)
    }
    return () => {
      document.removeEventListener('keypress', keyPressed)
    }
  }, [])

  function keyPressed(e) {
    if (document.activeElement.tagName === 'TEXTAREA') return
    if (e.which === 13) props.onNext()
  }
  return (
    <div className={styles.root}>
      {props.buttons.map(
        b =>
          b.show && (
            <ControlButton
              key={b.type}
              type={b.type}
              label={props.lables[b.type]}
              onClick={b.type === 'back' ? props.onPrev : props.onNext}
              locale={props.locale}
              id={b.id}
            />
          )
      )}
    </div>
  )
}

Controls.propTypes = {
  buttons: PropTypes.array.isRequired,
  lables: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  locale: PropTypes.string.isRequired
}

export default Controls
