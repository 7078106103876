import React from 'react'
import PropTypes from 'prop-types'
import styles from './Finish.module.css'
function Finish(props) {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>{props.title}</h1>
      <div className={styles.divider}></div>
      <p className={styles.text}>{props.text}</p>
    </div>
  )
}

Finish.propTypes = {}

export default Finish
