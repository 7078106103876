import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './Textarea.module.css'

function Textarea(props) {
  const textarea = useRef()
  useEffect(() => {
    textarea.current.focus()
  }, [])
  return (
    <div className={styles.root}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.areaWrap}>
        <textarea
          ref={textarea}
          className={styles.textarea}
          name={props.name}
          value={props.value}
          placeholder={props.placeholder}
          onChange={e => props.onUpdate('message', e.target.value)}
        ></textarea>
      </div>
    </div>
  )
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export default Textarea
