import React, { useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import localize from '../containers/localize'
import GraphQLErrorList from '../components/graphql-error-list'
import { GlobalDispatchContext } from '../containers/GlobalContextProvider'
import SEO from '../components/seo'
import Container from '../components/Container'
import ContactForm from '../components/contact/Form'
import Footer from '../components/Footer'

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      title {
        _type
        sl
        en
      }
      description {
        _type
        sl
        en
      }
    }
    companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
      name
      email
      phone
      street
      zipCode
      city
      country
      youtube
      facebook
      instagram
      linkedin
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props
  if (errors) return <GraphQLErrorList errors={errors} />

  const dispatch = useContext(GlobalDispatchContext)

  useEffect(() => {
    dispatch({
      type: 'SET_PAGE_CONTEXT_DATA',
      data: props.pageContext
    })
    dispatch({ type: 'SET_TRANSITION_STATE', state: 'pageEntering' })
  }, [])

  const page = data.page

  return (
    <>
      <SEO description={page.description} title={page.title} />
      <Container>
        <ContactForm locale={props.pageContext.locale} />
      </Container>
      <Footer companyInfo={data.companyInfo} />
    </>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}

export default localize(ContactPage)
