import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import strings from './strings';
import styles from './Form.module.css';
import StepCounter from './StepCounter';
import Controls from './Controls';
import Input from './Input';
import Textarea from './Textarea';
import Choices from './Choices';
import Finish from './Finish';
import Intro from './Intro';

export default class Form extends Component {
  static propTypes = {
    locale: PropTypes.oneOf(['sl', 'en']).isRequired,
  };

  constructor(props) {
    super(props);

    this.locales = strings[props.locale];

    this.state = {
      step: 1,
      totalSteps: 6,
      data: {
        name: {
          value: '',
          valid: false,
        },
        email: {
          value: '',
          valid: false,
        },
        service: {
          value: '',
          valid: false,
        },
        message: {
          value: '',
          valid: false,
        },
      },
      emails: {
        'business-opportunity': 'info@agencija101.si',
        internship: 'zaposlitev@agencija101.si',
        employment: 'zaposlitev@agencija101.si',
        other: 'info@agencija101.si',
      },
      buttons: [
        { type: 'start', show: true },
        { type: 'back', show: false },
        { type: 'next', show: false },
        { type: 'send', show: false, id: 'btn-send' },
        { type: 'end', show: false },
      ],
      error: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locale !== this.props.locale) {
      this.locales = strings[this.props.locale];
    }
  }

  updateButtons() {
    let buttons = [];
    const { step } = this.state;
    if (step === 1) {
      buttons = this.state.buttons.map((b) => {
        if (b.type === 'start') return { ...b, show: true };
        else return { ...b, show: false };
      });
    } else if (step === 2) {
      buttons = this.state.buttons.map((b) => {
        if (b.type === 'next') return { ...b, show: true };
        else return { ...b, show: false };
      });
    } else if (step > 2 && step < 5) {
      buttons = this.state.buttons.map((b) => {
        if (b.type === 'next' || b.type === 'back') return { ...b, show: true };
        else return { ...b, show: false };
      });
    } else if (step === 5) {
      buttons = this.state.buttons.map((b) => {
        if (b.type === 'send' || b.type === 'back') return { ...b, show: true };
        else return { ...b, show: false };
      });
    } else if (step === 6) {
      buttons = this.state.buttons.map((b) => {
        if (b.type === 'end') return { ...b, show: true };
        else return { ...b, show: false };
      });
    }

    this.setState({ buttons });
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  isValid(step) {
    if (step === 1) return true;
    else if (step === 2) {
      return this.state.data.name.value !== '';
    } else if (step === 3) {
      return this.validateEmail(this.state.data.email.value);
    } else if (step === 4) {
      return this.state.data.service.value !== '';
    } else if (step === 5) {
      return this.state.data.message.value !== '';
    } else if (step === 6) return true;
  }

  showError(msg) {
    const error = msg || this.locales.steps[this.state.step].error;
    this.setState({ error });
  }
  clearError() {
    this.setState({ error: '' });
  }

  nextStep() {
    if (this.isValid(this.state.step)) {
      this.clearError();
      if (this.state.step === 5) {
        const params = new URLSearchParams({
          email: this.state.data.email.value,
          service: this.state.data.service.value,
          name: this.state.data.name.value,
          message: this.state.data.message.value,
          forward_to: this.state.emails[this.state.data.service.value],
        });

        axios
          .get(
            `https://api.agencija101.si/sender/send-mail?${params.toString()}`,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            }
          )
          .then((response) => {
            this.setState({ step: this.state.step + 1 }, () =>
              this.updateButtons()
            );
          })
          .catch((error) => {
            this.showError(
              this.props.locale === 'sl'
                ? 'Pri pošiljanju tvojega sporočila je šlo nekaj narobe. Prosimo osveži stran in poskusi znova'
                : 'Something went wrong sending your email. Please refresh the page and try again.'
            );
          });
      } else if (this.state.step === 6) {
        // Handle the final step if needed
      } else {
        this.setState({ step: this.state.step + 1 }, () =>
          this.updateButtons()
        );
      }
    } else {
      this.showError();
    }
  }

  prevStep() {
    if (this.state.step === 1) return;
    this.clearError();
    this.setState({ step: this.state.step - 1 }, () => this.updateButtons());
  }

  updateData(key, value) {
    let updatedData = { ...this.state.data };
    updatedData[key].value = value;
    this.setState({ data: updatedData });
  }

  render() {
    const { step, totalSteps } = this.state;
    const s = this.locales.steps;

    return (
      <div className={styles.root}>
        <div className={styles.centeredContent}>
          <StepCounter total={totalSteps} step={step}></StepCounter>
          {step === 1 && <Intro title={s[1].title} text={s[1].text}></Intro>}
          {step === 2 && (
            <Input
              name="name"
              type="text"
              label={s[2].title}
              placeholder={s[2].placeholder}
              value={this.state.data.name.value}
              onUpdate={this.updateData.bind(this)}
            ></Input>
          )}
          {step === 3 && (
            <Input
              name="email"
              type="email"
              label={s[3].title}
              placeholder={s[3].placeholder}
              value={this.state.data.email.value}
              onUpdate={this.updateData.bind(this)}
              helperText="E-mail bomo uporabili za odgovor na poslano sporočilo."
            ></Input>
          )}
          {step === 4 && (
            <Choices
              choices={s[4].options}
              label={s[4].title}
              value={this.state.data.service.value}
              onUpdate={this.updateData.bind(this)}
            ></Choices>
          )}
          {step === 5 && (
            <Textarea
              name="message"
              label={s[5].title}
              placeholder={s[5].placeholder}
              value={this.state.data.message.value}
              onUpdate={this.updateData.bind(this)}
            ></Textarea>
          )}
          {step === 6 && (
            <Finish
              title={s[6].title}
              text={s[6].text}
              sub={s[6].subtext}
            ></Finish>
          )}
          <p className={styles.error}>{this.state.error}</p>
          <Controls
            buttons={this.state.buttons}
            lables={this.locales.buttons}
            onNext={this.nextStep.bind(this)}
            onPrev={this.prevStep.bind(this)}
            locale={this.props.locale}
          ></Controls>
        </div>
      </div>
    );
  }
}
