import React from 'react'
import PropTypes from 'prop-types'
import styles from './Choices.module.css'

function Choice(props) {
  return (
    <div className="choiceWrap">
      <input
        className={styles.styledRadio}
        id={props.value}
        value={props.value}
        type="radio"
        name="service"
        checked={props.checked}
        onChange={() => props.onChange('service', props.value)}
      />
      <label htmlFor={props.value}>{props.label}</label>
    </div>
  )
}

function Choices(props) {
  return (
    <div className={styles.root}>
      <div className={styles.label}>{props.label}</div>
      <div className={styles.grid}>
        {props.choices.map(c => {
          return (
            <Choice
              key={c.value}
              value={c.value}
              label={c.label}
              onChange={props.onUpdate}
              checked={c.value === props.value}
            ></Choice>
          )
        })}
      </div>
    </div>
  )
}

Choices.propTypes = {
  choices: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired
}

export default Choices
