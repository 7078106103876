export default {
  sl: {
    buttons: {
      start: 'Začni',
      next: 'Naprej',
      back: 'Nazaj',
      send: 'Pošlji',
      end: 'Končaj'
    },
    steps: {
      1: {
        title: 'Tukaj nam lahko napišeš karkoli.',
        text:
          'Želiš delati pri nas, iščeš oglaševalsko agencijo ali pa te samo zanima, če smo mi ustvarili tisti oglas, ki ti gre silovito na živce?'
      },
      2: {
        title: 'Tvoje ime',
        placeholder: 'Vnesi svoje ime',
        error: 'Lepo bi bilo, če se nam predstaviš.'
      },
      3: {
        title: 'Tvoj e-mail naslov',
        placeholder: 'Vnesi svoj e-mail',
        error: 'Preveri, ali si pravilno vnesel svoj e-mail, da te bomo lahko kontaktirali.'
      },
      4: {
        title: 'Kaj te zanima?',
        options: [
          { value: 'business-opportunity', label: 'Poslovna priložnost' },
          { value: 'internship', label: 'Praktično usposabljanje' },
          { value: 'employment', label: 'Zaposlitev' },
          { value: 'other', label: 'Drugo' }
        ],
        error: 'Prosim, če nam zaupaš kaj te zanima.'
      },
      5: {
        title: 'Tvoje sporočilo',
        placeholder: 'Napiši tukaj',
        error: 'Bi nam mogoče kaj napisal?'
      },
      6: {
        title: 'Hvala!',
        text:
          'Tvoje sporočilo je pravkar priletelo v naš inbox in z zvočnim signalom naznanilo svoj prihod, tako da ga verjetno nekdo že prebira in kuje odgovor. Razen, če je čas kosila. Potem bo sporočilo prebrano nekoliko kasneje.',
        subtext: 'V vsakem primeru se slišimo kmalu.'
      }
    }
  },
  en: {
    buttons: {
      start: 'Start',
      next: 'Continue',
      back: 'Back',
      send: 'Send',
      end: 'Finish'
    },
    steps: {
      1: {
        title: 'Here you can message us anything you want.',
        text:
          'Do you want to work with us, are you looking for an advertising agency or are you just interested if we created the ad that immensely gets on your nerves?'
      },
      2: {
        title: 'Your Name',
        placeholder: 'Enter your name',
        error: 'It would be nice, if you introduce yourself.'
      },
      3: {
        title: 'Your e-mail address',
        placeholder: 'Enter your e-mail',
        error: 'Check if you wrote your email correctly, so that we will be able to contact you.'
      },
      4: {
        title: 'What interests you?',
        options: [
          { value: 'business-opportunity', label: 'Business opportunity' },
          { value: 'internship', label: 'Internship' },
          { value: 'employment', label: 'Employment' },
          { value: 'other', label: 'Other' }
        ],
        error: 'Let us know what is your interest.'
      },
      5: {
        title: 'Your message',
        placeholder: 'Enter your message',
        error: 'Would you like to write us something?'
      },
      6: {
        title: 'Thank you!',
        text:
          "Your message has just arrived in our inbox, so  it's likely that somebody is already reading it and writing you an anwser. Unless it's lunch time. Then your message will be read later.",
        subtext: 'In any case you will hear from us soon.'
      }
    }
  }
}
