import React from 'react'
import PropTypes from 'prop-types'
import styles from './StepCounter.module.css'
function StepCounter(props) {
  return (
    <div className={styles.root}>
      <span className="current">{props.step}</span>
      <span className="divider">/</span>
      <span className="total">{props.total}</span>
    </div>
  )
}

StepCounter.propTypes = {
  total: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired
}

export default StepCounter
